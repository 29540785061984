import "./App.css";
import {
  Route,
  Routes,
  useParams
} from "react-router-dom";
import { withAppBar } from "./hoc/withAppBar";
import Home from "./Home";
import Dice from "./Dice";
import Roll from "./Roll";
import Relay from "./Relay";
import Assets from "./Assets";

function App() {
  const params = useParams();
  return (
    <Routes>
      <Route path="/" element={<Assets />} />
      <Route path="/:appId" element={<Roll />} exact />
      <Route path="/dice" element={<Home />} />
      <Route exact path="/dice/:poolId" element={<Dice />} />
      <Route path="/relay" element={<Relay />} />
    </Routes>
  );
}

export default withAppBar(App);
