import { default as MyAlgoConnect } from "@randlabs/myalgo-connect";
import algosdk from "algosdk";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./App.css";
import {
  getAccountInfo,
  getAsset,
  getProviderAdminTokens,
  randomArchirand,
} from "./functions";
import { CircularProgress, Grid, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import appService from "./services/appService";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import { useReach } from "./hooks/useReach";
import useLocalStorage from "./hooks/useLocalStorage";

import * as diceBackend from "./build/dice/index.main.mjs";
import { Image } from "react-bootstrap";

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_ADDR } = process.env;

const providerEnv =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "TestNet";

const algoexplorer =
  providerEnv === "MainNet"
    ? "https://node.testnet.algoexplorerapi.io"
    : "https://node.algoexplorerapi.io";

const CssTextField = styled(TextField)({
  "& .MuiTextField-root": {
    color: "white",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      color: "white",
    },
    "&:hover fieldset": {
      borderColor: "yellow",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiOutlinedInput-input": {
    "&.Mui-disabled": {
      color: "white",
      "-webkit-text-fill-color": "inherit",
    },
  },
});

function App() {
  const reach = useReach();
  const [addr, setAddr] = useLocalStorage("addr", null);
  const navigate = useNavigate();
  const { poolId } = useParams();
  const [appId, asset0, asset1, asset2, asset3, asset4, asset5, asset6] = poolId
    ? poolId.split("-")
    : [];
  const initialState = {
    acc: null,
    addrs:
      localStorage.getItem("state") &&
      (Object.keys(JSON.parse(localStorage.getItem("state"))?.memo2) || []).map(
        (el) => ({ addr: el })
      ),
    success: false,
    confetti: false,
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    ASSET0: asset0,
    ASSET1: asset1,
    ASSET2: asset2,
    ASSET3: asset3,
    ASSET4: asset4,
    ASSET5: asset5,
    ASSET6: asset6,
  });

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    //console.log({ name, value });
    switch (name) {
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "SWAPT":
      case "SWAPF":
      case "INFO":
      case "EXCHANGE":
      case "PASS":
      case "PLAN":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleConnect = async () => {
    try {
      //console.log("Connecting ...");
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      const balAtomic = await reach.balanceOf(acc);
      const bal = reach.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(acc.networkAccount.addr);
      // token gate
      if (
        !((toks) =>
          accInfo.account.assets
            .map((el) => el["asset-id"])
            .reduce((acc, val) => acc || toks.includes(val), false))(
          getProviderAdminTokens()
        )
      ) {
        navigate("/");
      }
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleDisconnect = () => setState(initialState);

  const handleDeposit = async () => {
    if (!state.acc) return;
    // TODO add validation
    setLoading(true);
    // XXX get app info
    const { info: appId } = await appService.createDice();
    navigate(
      `/dice/${appId}-${[asset0, asset1, asset2, asset3, asset4, asset5].join(
        "-"
      )}`
    );
    const ctc = state.acc.contract(diceBackend, parseInt(appId));
    await Promise.all([
      diceBackend.Contractee(ctc, {}),
      diceBackend.Alice(ctc, {
        getParams: () => ({
          price: reach.parseCurrency(query.AMOUNT),
          tokens: [asset0, asset1, asset2, asset3, asset4, asset5],
          addr: REACT_APP_ADDR,
        }),
        signal: () => {
          navigate("/");
        },
      }),
    ]);
    setLoading(false);
  };

  const handleClose = async () => {
    if (!state.acc) return;
    // TODO add validation
    //setLoading(true);
    const ctc = state.acc.contract(diceBackend, parseInt(appId));
    await diceBackend.Bob(ctc, {});
  };

  const handleRoll = async () => {
    if (!state.acc) return;
    // TODO add validation
    //setLoading(true);
    const ctc = state.acc.contract(diceBackend, parseInt(appId));
    await ctc.a.touch();
  };
  const handleRoll2 = async () => {
    if (!state.acc) return;
    const myAlgoWallet = new MyAlgoConnect();
    const algodClient = new algosdk.Algodv2("", algoexplorer, "");
    const params = await algodClient.getTransactionParams().do();
    params.fee = 9;
    const from = state.acc.networkAccount.addr;
    const txns = [
      algosdk.makeApplicationNoOpTxnFromObject({
        from,
        suggestedParams: params,
        appIndex: parseInt(74985032),
        appArgs: [
          new Uint8Array(Buffer.from("Bg==", "base64")),
          new Uint8Array(Buffer.from("AAAAAAAAAAA=", "base64")),
          new Uint8Array(Buffer.from("", "base64")),
        ],
        foreignAssets: [71567479],
      }),
      algosdk.makeApplicationNoOpTxnFromObject({
        from,
        suggestedParams: params,
        appIndex: parseInt(74992338),
        appArgs: [
          new Uint8Array(Buffer.from("Bg==", "base64")),
          new Uint8Array(Buffer.from("AAAAAAAAAAA=", "base64")),
          new Uint8Array(Buffer.from("", "base64")),
        ],
        foreignAssets: [71857600],
      }),
    ];
    //let txgroup = algosdk.assignGroupID(txns, from);
    let stxn = await myAlgoWallet.signTransaction(
      txns.map((el) => el.toByte())
    );
    let res = await algodClient
      .sendRawTransaction(stxn.map((el) => el.blob))
      .do();
    //console.log(res);
  };

  return state.acc ? (
    <>
      <Container className="p-5 text-dark">
        <Box sx={{ m: 3 }}>
          <Typography variant="h4">Almost there!</Typography>
          <Typography variant="p">
            Deposit to release your dice into the wild!
          </Typography>
        </Box>
        <Grid container>
          {[...Array(6).keys()]
            .map((el) => query[`ASSET${el}`])
            .map((el) => randomArchirand(el))
            .map((el) => (
              <Grid item xs={4}>
                <Image fluid src={`/assets/${el}.png`} />
              </Grid>
            ))}
        </Grid>
        {false &&
          [...Array(6).keys()].map((i) => (
            <>
              <Box sx={{ m: 3 }}>
                <Typography variant="heading">Side {i + 1}</Typography>
              </Box>
              <Box sx={{ m: 3 }}>
                <TextField
                  disabled
                  defaultValue={query[`ASSET${i}`]}
                  label="Asset id"
                  id="custom-css-outlined-input"
                />
              </Box>
            </>
          ))}
        {!loading ? (
          <>
            <Box sx={{ m: 3 }}>
              <Typography variant="heading">Amount</Typography>
            </Box>
            <Box sx={{ m: 3 }}>
              <TextField
                defaultValue={query.AMOUNT}
                label="Amount"
                id="custom-css-outlined-input"
                fullWidth
                onChange={({ target }) =>
                  setQuery({ ...query, AMOUNT: target.value })
                }
              />
            </Box>
            <Box sx={{ m: 5 }}>
              <Button onClick={handleDeposit} className="w-100">
                Deposit
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", m: 5 }}>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </>
  ) : (
    <div
      style={{
        textAlign: "center",
        height: "60vh",
        verticalAlign: "middle",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        color: "black",
        fontSize: "30px",
      }}
    >
      <CircularProgress color="inherit" size={100} />
    </div>
  );
}

export default App;
