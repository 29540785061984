import { useEffect, useState } from "react";
import * as backend from './build/relay/index.main.mjs'
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./App.css";
import { getAccountInfo, getAsset } from "./functions";
import {
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import appService from "./services/appService";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// AssetSelector
import AssetSellector from "./components/AssetSelector";

// Noodle
import { useReach } from "./hooks/useReach";
import useLocalStorage from "./hooks/useLocalStorage";

import { styled } from "@mui/material/styles";

import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";

import { CubeTextureLoader, MeshBasicMaterial, CubeTexture } from "three";
import LogoCube from "./LogoCube";
import Icon from "react-crypto-icons";

function Dice(props) {
  const loader = new CubeTextureLoader();
  loader.setPath("/");
  const images = [
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
    "https://infura-ipfs.io/ipfs/bafkreig3b2yl32qmuwoqijrt5okazenyszau66wrioepv6k3lbea2fkbnu",
  ];
  const textureCube = loader.load(images);
  const material = new MeshBasicMaterial({
    color: 0xffffff,
    envMap: textureCube,
  });

  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => {
    ref.current.rotation.x += 0.01;
    ref.current.rotation.y += 0.01;
  });
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? 2 : 1}
      onClick={(event) => click(!clicked)}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={"orange"} />
    </mesh>
  );
}

const { REACT_APP_NETWORK_PROVIDER } = process.env;

const providerEnv =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "MainNet";

function App() {
  const reach = useReach();
  const [addr, setAddr] = useLocalStorage("addr", null);
  const navigate = useNavigate();
  const { poolId } = useParams();
  const [_, asset0, asset1, asset2, asset3, asset4, asset5, asset6] = poolId
    ? poolId.split("-")
    : [];
  const initialState = {
    acc: null,
    addrs:
      localStorage.getItem("state") &&
      (Object.keys(JSON.parse(localStorage.getItem("state"))?.memo2) || []).map(
        (el) => ({ addr: el })
      ),
    success: false,
    confetti: false,
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    ASSET0: asset0,
    ASSET1: asset1,
    ASSET2: asset2,
    ASSET3: asset3,
    ASSET4: asset4,
    ASSET5: asset5,
    ASSET6: asset6,
  });

  useEffect(() => {
    if (addr) {
      handleConnect();
    }
  }, []);

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    console.log({ name, value });
    switch (name) {
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "SWAPT":
      case "SWAPF":
      case "INFO":
      case "EXCHANGE":
      case "PASS":
      case "PLAN":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleConnect = async () => {
    try {
      console.log("Connecting ...");
      let acc;
      if (addr) {
        acc = await reach.connectAccount({ addr });
      } else {
        acc = await reach.getDefaultAccount();
        setAddr(acc.networkAccount.addr);
      }
      const balAtomic = await reach.balanceOf(acc);
      const bal = reach.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(acc.networkAccount.addr);
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleDisconnect = () => setState(initialState);

  const handleDiceCreate = async () => {
    // TODO add validation
    setLoading(true);
    const { info } = await appService.createDice();
    const diceId = [
      info,
      query.ASSET0,
      query.ASSET1,
      query.ASSET2,
      query.ASSET3,
      query.ASSET4,
      query.ASSET5,
      query.ASSET6,
    ].join("-");
    navigate(`/dice/${diceId}`);
  };

  const handleRelayCreate = async () => {
    setLoading(true);
    const { info: appId } = await appService.createRelay();
    const ctc = state.acc.contract(backend, parseInt(appId))
    Promise.all([
      backend.Contractee(ctc, {}),
      backend.Alice(ctc, {})
    ])
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Container className="p-5">
        {!loading ? (
          <Box sx={{ m: 5 }}>
            <Button onClick={handleRelayCreate} className="w-100">
              Create Relay
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </>
  );
}

export default App;
